<!-- GoodItem -->
<template>
    <div class="good-item-container" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="toGoodDetail(goodInfo.id)">
        <el-image :src="goodInfo.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 210px; height: 210px;">
            <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 210px; height: 210px;">
        </el-image>
        <div>
            <p class="mt-7 line-clamp"><span v-if="goodInfo.productType == 3" class="meta">秒杀</span>{{goodInfo.title}}</p>
            <p class="mt-7">
                <span v-if="goodInfo.productType == 3" class="color-orange font-20">￥{{goodInfo.miaoPrice}}</span>
                <span v-else-if="$store.state.userInfo.medicine == 1 && goodInfo.medicine == 1" 
                            class="color-orange font-20">￥{{goodInfo.medicinePrice}}</span>
                <span v-else-if="$store.state.userInfo.type == 6 && goodInfo.drugstorePrice" 
                            class="color-orange font-20">￥{{goodInfo.drugstorePrice}}</span>
                <span v-else class="color-orange font-20">￥{{goodInfo.marketprice}}</span>
                <span v-if="goodInfo.productprice && goodInfo.medicine != 1" 
                        class="color-999 font-12 ml-10 text-line">￥{{goodInfo.productprice}}</span>
            </p>
            <p class="color-999 font-12 mt-7 line-clamp">{{goodInfo.productionEnterprise}}</p>
        </div>
        <el-collapse-transition>
            <div v-show="show" class="good-item-info" @click.stop.prevent>
                <div>
                    <p class="line-clamp2">{{goodInfo.title}}</p>
                    <p class="mt-7">
                        <template v-if="!goodInfo.hasoption">
                            <span v-if="goodInfo.productType == 3" class="color-orange font-20">￥{{goodInfo.miaoPrice}}</span>
                            <span v-else-if="$store.state.userInfo.medicine == 1 && goodInfo.medicine == 1" 
                                        class="color-orange font-20">￥{{goodInfo.medicinePrice}}</span>
                            <span v-else-if="$store.state.userInfo.type == 6 && goodInfo.drugstorePrice" 
                                        class="color-orange font-20">￥{{goodInfo.drugstorePrice}}</span>
                            <span v-else class="color-orange font-20">￥{{goodInfo.marketprice}}</span>
                            <span v-if="goodInfo.productprice && goodInfo.medicine != 1" 
                                    class="color-999 font-12 ml-10 text-line">￥{{goodInfo.productprice}}</span>
                        </template>
                        <template v-else-if="goodInfo.skus.length>0">
                            <span class="color-orange font-20">￥{{goodInfo.skus[skuIndex].marketprice}}</span>
                            <span class="color-999 font-12 ml-10 text-line">￥{{goodInfo.skus[skuIndex].productprice}}</span>
                        </template>
                    </p>
                    <template v-if="!goodInfo.hasoption">    
                        <p class="color-666 font-12 mt-7">规格：{{goodInfo.specTitle}}<template v-if="goodInfo.unit">/{{goodInfo.unit}}</template></p>
                        <p class="color-666 font-12 mt-7">库存：{{Math.max(goodInfo.total - goodInfo.salesreal, 0)}}</p>
                    </template>
                    <template v-else>
                        <p class="color-666 font-12 mt-7" v-if="goodInfo.skus.length>0">库存：{{Math.max(goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal, 0)}}</p>
                        <div class="flex color-666 font-12 mt-7">
                            <span class="shrink-0">规格：</span>
                            <ul class="flex flex-wrap flex-1">
                                <li class="sku-item" :class="skuIndex == index ? 'active' : ''"
                                        v-for="(item, index) in goodInfo.skus" :key="item.id" @click="changeSkuFn(index)">{{item.title}}</li>
                            </ul>
                        </div>
                    </template>
                    <div class="mt-10 flex justify-s-b align-c">
                        <template v-if="!goodInfo.hasoption">
                            <el-input-number size="mini" v-model="num" :min="1" 
                                :max="goodInfo.maxbuy ? Math.min(goodInfo.maxbuy, (goodInfo.total-goodInfo.salesreal)) : (goodInfo.total-goodInfo.salesreal)"></el-input-number>
                        </template>
                        <template v-else-if="goodInfo.skus.length>0">
                            <el-input-number size="mini" v-model="num" :min="1" 
                                :max="goodInfo.maxbuy ? Math.min(goodInfo.maxbuy, (goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal)) : (goodInfo.skus[skuIndex].stock - goodInfo.skus[skuIndex].salesreal)"></el-input-number>
                        </template>
                        <template v-if="goodInfo.timebegin">
                            <el-button v-if="goodInfo.productType == 3" size="mini" disabled>{{goodInfo.miaotimestart | dateFormat('MM-DD HH:mm')}}开始</el-button>
                            <el-button v-else size="mini" disabled>等待开团</el-button>
                        </template>
                        <el-button v-else-if="goodInfo.timeover" size="mini" disabled>已停止购买</el-button>
                        <el-button v-else-if="goodInfo.shouqing" size="mini" disabled>已售罄</el-button>
                        <el-button v-else size="mini" @click="addCart">加入购物车</el-button>
                    </div>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
export default {
    name: 'GoodItem',
    components: {},

    props: {
        goods: {
            type: Object,
            default: function() {
                return {}
            }
        }

    },

    data() {
        return {
            num: 1,
            show: false,
            skuIndex: 0,
            goodInfo: this.goods,
            timer: null
        };
    },

    created() {

    },

    methods: {
        //触摸时实时判断销售状态
        mouseenter(){
            let serverTime = Date.now()
            if(this.goodInfo.productType == 3){
                this.goodInfo.timeover = serverTime > this.$dayjs(this.goodInfo.miaotimeend).valueOf()//秒杀结束
                this.goodInfo.timebegin = serverTime < this.$dayjs(this.goodInfo.miaotimestart).valueOf()//秒杀开始
            } else {
                this.goodInfo.timeover = serverTime > this.$dayjs(this.goodInfo.timeend).valueOf()//商品结束
                this.goodInfo.timebegin = serverTime < this.$dayjs(this.goodInfo.timestart).valueOf()//商品开卖
            }
            if(this.goodInfo.hasoption && !this.goodInfo.skus){
                this.timer = setTimeout(()=>{
                    this.getGoodSku()
                }, 200)
                return
            } else if(this.goodInfo.hasoption && this.goodInfo.skus) {
                this.shouqingFn()
                this.$nextTick(() => {
                    this.show = true
                })
                return
            }
            this.goodInfo.shouqing = (this.goodInfo.total <= this.goodInfo.salesreal)   //商品售罄  
            this.show = true
        },

        mouseleave() {
            if(this.timer)
                clearTimeout(this.timer)
            this.show = false
        },

        //查询商品多规格
        getGoodSku () {
            this.$https.get(this.$api.productSku,{
                productId: this.goodInfo.id
            })
            .then((res) =>{
                this.goodInfo.skus = res.data
                this.shouqingFn()
                this.$nextTick(() => {
                    this.show = true
                })  
            })
        },

        shouqingFn(){
            if(this.goodInfo.skus.length > 0)
                this.goodInfo.shouqing = (this.goodInfo.skus[this.skuIndex].stock <= this.goodInfo.skus[this.skuIndex].salesreal)   //商品售罄
        },

        //切换规格
        changeSkuFn(index) {
            if(this.skuIndex == index) return
            this.skuIndex = index
            this.num = 1
            this.shouqingFn()
        },

        //加入购物车
        addCart(){
            let goodInfo = this.goodInfo
            let userInfo = this.$store.state.userInfo
            let cartItem = {
                mid: userInfo.id,
                centreId: userInfo.centreId,
                teamId: userInfo.upshopTid,
                openid: userInfo.openid,
                productId: goodInfo.id,
                total: this.num,
                marketprice: (userInfo.isVip && goodInfo.vipprice && goodInfo.isvip && userInfo.vipofcentreId.indexOf(userInfo.centreId) != -1) ? goodInfo.vipprice : goodInfo.productType == 3 ? goodInfo.miaoPrice : (userInfo.medicine == 1 && goodInfo.medicine == 1) ? goodInfo.medicinePrice : (userInfo.type == 6 && goodInfo.drugstorePrice) ? goodInfo.drugstorePrice : goodInfo.marketprice,
                vipprice: goodInfo.vipprice
            }

            if(goodInfo.hasoption){
                cartItem.skuId = goodInfo.skus[this.skuIndex].id;
                cartItem.skuTitle = goodInfo.skus[this.skuIndex].title;
                cartItem.marketprice = (userInfo.isVip && goodInfo.skus[this.skuIndex].vipprice && goodInfo.isvip) ? goodInfo.skus[this.skuIndex].vipprice : goodInfo.skus[this.skuIndex].marketprice;
                cartItem.vipprice = goodInfo.skus[this.skuIndex].vipprice;
            }

            this.$https.get(this.$api.addCart, cartItem)
            .then((res) => {
                this.$message({
                    message: '加入购物车成功',
                    type: 'success'
                })
                this.$store.dispatch('GET_CART_TOTAL')
            })
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id 
                                }
                            });
            window.open(href, '_blank')
        }
    }
}
</script>
<style lang='scss' scoped>
    .good-item-container{
        width: 230px;
        padding: 9px 9px 14px;
        border: 1px solid #DEDEDE;
        position: relative;
        cursor: pointer;
        &:hover{
            box-shadow: 0 0 5px #ccc;
        }
        .meta{
            width: 38px;
            height: 18px;
            padding-left: 3px;
            margin-right: 2px;
            line-height: 18px;
            color: #fff;
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
            background: url(../../assets/images/sprite.png) no-repeat;
            background-position: -161px -77px;
            box-sizing: border-box;
        }
        .good-item-info{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
            cursor: initial;
            > div{
                padding: 9px 9px 14px;
            }
        }

        .sku-item{
            min-width: 50px;
            padding: 4px 5px;
            margin: 0 7px 4px 0;
            font-size: 12px;
            text-align: center;
            border: 1px solid #ccc;
            background-color: #f7f7f7;
            cursor: pointer;
            &.active,
            &:hover{
                border-color: var(--red-color);
            }
        }
    }
</style>