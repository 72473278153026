<!-- 优惠券弹窗 -->
<template>
    <div>
        <div v-if="couponList.length > 0" class="coupon-container">
            <img class="coupon-tit" src="../../assets/images/coupon_tit.png">
            <div class="coupon-wrapper">
                <ul class="coupon-list flex-column align-c">
                    <li class="coupon-item flex align-c color-fff" v-for="(item, index) in couponList" :key="index">
                        <div v-if="item.type == 2" class="coupon-item-l font-24">{{item.amount.toFixed(1)}}折</div>
                        <div v-else class="coupon-item-l font-24">￥{{item.amount}}</div>
                        <div class="flex-column flex-1 justify-c">
                            <p class="font-14">{{item.ticketName}}</p>
                            <p class="font-12 mt-10">
                                <span v-if="item.type == 1 && item.limit">{{item.limit}}张，</span>
                                <span>{{item.condition ? "满" + item.condition + "元可用" : "无使用门槛"}}</span>
                            </p>
                            <p class="font-12 mt-10">有效期截至：{{item.endTime | dateFormat('YYYY-MM-DD')}}</p>
                        </div>
                    </li>
                </ul>
                <div class="receive-btn" @click="receiveFn">立即领取</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Coupon',
    components: {},

    data() {
        return {
            couponList: [],
            ticketId: []
        };
    },

    created() {
        this.getLogincoup()
    },

    methods: {
        //优惠券列表
        getLogincoup(){
            this.$https.get(this.$api.logincoup,{
                centreid: this.$store.state.userInfo.centreId,
                memberid: this.$store.state.userInfo.id,
                state: 0
            })
            .then((res) =>{
                let temp = []
                res.data.forEach(item =>{
                    temp.push(item.id)
                })
                this.ticketId = temp.join(',')
                this.couponList = res.data
            })
        },

        //领取
        receiveFn(){
            this.$https.get(this.$api.recieveCoup,{
                ticketid: this.ticketId,
                memberId: this.$store.state.userInfo.id,
            })
            .then((res) =>{
                this.$message({
                    message: '领取成功',
                    type: 'success'
                })
                this.couponList = []
            })
        }
    }
}
</script>
<style lang='scss' scoped>
    .coupon-container{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,.6);
        z-index: 99;
        .coupon-tit{
            width: 320px;
        }
        .coupon-wrapper{
            width: 440px;
            padding-top: 40px;
            margin-top: 20px;
            border-radius: 15px;
            border: 5px solid #020066;
            background-color: #ffffff;
            .coupon-list{
                max-height: 345px;
                overflow: auto;
                &::-webkit-scrollbar {
                    width:6px;
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
                    border-radius:10px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius:10px;
                    background:rgba(0,0,0,.15);
                    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
                }
                &::-webkit-scrollbar-thumb:window-inactive {
                    background:rgba(255,0,0,.4);
                }
                .coupon-item{
                    width: 300px;
                    height: 100px;
                    flex-shrink: 0;
                    margin-bottom: 15px;
                    background: url(../../assets/images/coupon.png);
                    background-size: 300px 100px;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    .coupon-item-l{
                        width: 103px;
                        text-align: center;
                        margin-right: 20px;
                    }
                }
            }
            .receive-btn{
                width: 150px;
                height: 34px;
                margin: 10px auto 30px;
                line-height: 34px;
                border-radius: 7px;
                color: #fff;
                font-weight: bold;
                text-align: center;
                cursor: pointer;
                background-color: var(--theme-color);
            }
        }
    }
</style>