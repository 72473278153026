<!-- 首页 -->
<template>
	<div class="container">
		<!-- banner栏-START  -->
		<div class="flex mb-30">
			<div class="classify-container">
				<span v-for="(item,index) in classifyList" :key="item.id" 
						@click="toGoodList(item.name, item.id)">{{item.name}}</span>
			</div>
			<div class="banner-container">
				<el-carousel trigger="click">
					<el-carousel-item v-for="(item, index) in bannerList" :key="index">
						<img :src="item.thumb">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="sidebar-container">
				<div class="sidebar-qrcode-box flex-column align-c">
					<img class="qrcode" src="../../assets/images/qr_code.jpg">
					<div class="qrcode-tips"></div>
				</div>

				<div class="merquee-container flex align-c">
					<!-- <div class="merquee-icon shrink-0"></div>
					<div class="flex-1 min-width-0"> -->
					<marquee :text="notice" />
					<!-- </div> -->
				</div>
				<ul class="meta-container color-666 flex justify-s-a">
					<router-link :to="{name: 'myOrder'}" tag="li" class="flex-column align-c point">
						<div class="order"></div>
						<p class="mt-10">我的订单</p>
					</router-link>
					<router-link :to="{name: 'myCoupon'}" tag="li" class="flex-column align-c point">
						<div class="coupon"></div>
						<p class="mt-10">我的优惠券</p>
					</router-link>
				</ul>
			</div>
		</div>
		<!-- banner栏-END -->

		<!-- 专区组件 -->
		<template v-for="item in typeList">
			<SpecialZone :type="item"/>
		</template>

		<coupon/>
	</div>
</template>

<script>
import SpecialZone from '@/components/specialZone'
import marquee from '@/components/marquee'
import coupon from '@/components/coupon'

export default {
	name: 'Home',
	components: {
		SpecialZone,
		marquee,
		coupon
	},

	data() {
		return {
			notice: '',
			classifyList: [],
			bannerList: [],
			typeList: [3, 16, 17, 18, 13, 0], //专区类型：3秒杀/16(控销)/17(基药)/18(指定)/13(中药)/0(热销推荐)
		};
	},

	created() {
		this.getClassify()
		this.getBanner()
		this.getNotice()
	},

	methods: {
		//快捷分类
		getClassify() {
			this.$https.get(this.$api.subject,{
                type: 4,
                centreId: this.$store.state.userInfo.centreId
            })
            .then((res) =>{
				let classifyList = res.data.filter(item => item.parentId !== 10)
                this.classifyList = classifyList
            })
		},

		//轮播图
		getBanner() {
			this.$https.get(this.$api.banner,{
                type: 1,
                centerId: this.$store.state.userInfo.centreId,
                top: 10
            })
            .then((res) =>{
                this.bannerList = res.data
            })
		},

		//公告
		getNotice() {
			this.$https.get(this.$api.notice,{
                centerId: this.$store.state.userInfo.centreId
            })
            .then((res) =>{
                if(res.data && res.data.rows.length > 0){
					this.notice = res.data.rows[0].content.replace(/[\r\n]/g,"")
				}
            })
		},

		toGoodList(name, categoryId) {
			this.$router.push({
				name: 'goodList',
				query: {
					name: encodeURIComponent(name),
					categoryId: categoryId
				}
			})
		}
	}
}
</script>
<style lang='scss' scoped>
	.classify-container{
		width: 256px;
		height: 340px;
		padding: 23px 12px 3px;
		background-color: #F1F1F1;
		span{
			display: inline-block;
			margin: 0 10px 15px;
			cursor: pointer;
			&:hover{
				color: var(--blue-color);
			}
		}
	}
	.banner-container{
		width: 700px;
		height: 340px;
		img{	
			width: 700px;
			height: 340px;
		}
	}
	.sidebar-container{
		width: 246px;
		height: 340px;
		.sidebar-qrcode-box{
			height: 190px;
			padding-top: 15px;
			background: linear-gradient(180deg, rgba(12, 56, 144, 0.7) 1%, rgba(12, 56, 144, 0) 100%);
			.qrcode{
				width: 100px;
				height: 100px;
			}
			.qrcode-tips{
				width: 130px;
				height: 40px;
				margin-top: 15px;
				background: url(../../assets/images/sprite.png) no-repeat;
				background-position: 0 -112px;
			}
		}
		.merquee-container{
			height: 30px;
			padding: 0 10px;
			margin-top: 10px;
		}
		.meta-container{
			padding: 0 10px;
			margin-top: 20px;
			.order,.coupon{
				width: 30px;
				height: 28px;
				background: url(../../assets/images/sprite.png) no-repeat;
				background-position: -85px -74px;
			}
			.coupon{
				background-position: -48px -74px;
			}
		}
	}
</style>
