<template>
  <transition name="fade">
    <div class="noticebar">
      <div class="icon shrink-0"></div>
      <div ref="back" class="back">
        <span ref="text" class="text">{{ text }}</span>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Marquee',
  props: {
    text: ''
  },

  data() {
    return {
      speed: 50, // 速度（单位px/s）
      backWidth: "", // 父级宽度
      backHeight: "", // 父级高度
      wordLength: "", // 文本长度
      state: 1,
      firstAnimationTime: "", // 状态一动画效果
      secondAnimationTime: "", // 状态二动画效果
      delay: 1000,
      timer: null
    };
  },
  methods: {
    // 获取数据
    getData() {
      let style = document.styleSheets[0]
      let text = this.$refs.text
      let back = this.$refs.back
      this.backWidth = back.offsetWidth
      this.backHeight = back.offsetHeight
      text.style.lineHeight = this.backHeight + "px"
      this.wordLength = text.offsetWidth
      this.ComputationTime()  // 计算时间
      style.insertRule(
        `@keyframes firstAnimation {0% {left:0px;}100% {left:-${this.wordLength}px;}}`
      );
      style.insertRule(
        `@keyframes secondAnimation {0% {left:${this.backWidth}px;}100% {left:-${this.wordLength}px;}}`
      );
      this.timer = setTimeout((res) => {
                    this.changeState()
                  }, this.delay)
    },

    // 用速度计算时间（想要保持速度一样，2种状态时间不同需算出）
    ComputationTime() {
      this.firstAnimationTime = this.wordLength / this.speed
      this.secondAnimationTime =
        (this.wordLength + this.backWidth) / this.speed
    },

    // 根据状态切换动画
    changeState() {
      let text = this.$refs.text
      if (this.state == 1) {
        text.style.animation = `firstAnimation ${this.firstAnimationTime}s linear`
        this.state = 2
      } else {
        text.style.animation = `secondAnimation ${this.secondAnimationTime}s linear infinite`
      }
    },
    Listener() {
      let text = this.$refs.text
      text.addEventListener( "animationend", (res) => {
        this.changeState()
      },false)
    },
  },

  mounted() {
    this.Listener()
    this.timer = setTimeout((res) => {
                    this.getData()
                  }, 100)
  },

  beforeDestroy() {
      clearTimeout(this.timer)
  },
};
</script>
<style lang="scss" scoped>
.noticebar {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background: url(../../assets/images/sprite.png) no-repeat;
    background-position: -124px -80px;
  }
  .back {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
    .text {
      position: absolute;
      display: inline-block;
      padding: 2px 0;
    }
  }
}
</style>